//const server = 'http://192.168.80.153:5000/where/v1/'
//const websocketServer = 'ws://192.168.80.153:5000/stone/'

//const server = 'https://stone.test.dev.ncv.jp/where/v1/'
//const websocketServer = 'wss://stone.test.dev.ncv.jp/socket/stone/'

const server = 'https://stone.dev.ncv.jp/where/v1/'
const websocketServer = 'wss://stone.dev.ncv.jp/socket/stone/'

export default {
  ws: websocketServer,
  
  stone: {
    url: server + 'main'
  },
}

